import {Box} from "@mui/material";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Hero from "../Component/Hero/Hero";
import Features from "../Block/Features/Features";

const FinLanding = () => {
  return <Box height="100%">
    <Header />

    <Hero />
    <Features />

    <Footer />
  </Box>
}

export default FinLanding;


