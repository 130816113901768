import {Container, Stack, Typography} from "@mui/material";
import SocialIcon from "../SocialIcon/SocialIcon";
import {CicapLogoIcon} from "../../Icon/CicapLogoIcon";
import {TelegramIcon} from "../../Icon/TelegramIcon";
import {YouTubeIcon} from "../../Icon/YouTubeIcon";
import {VkIcon} from "../../Icon/VkIcon";
import {InternetIcon} from "../../Icon/InternetIcon";

const Header = () => {
  return <Container maxWidth="xl" sx={{py: 4, position: "absolute", top: 0, left: 0, right: 0, color: "#949393", zIndex: 1000}}>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <CicapLogoIcon sx={{height: {xs: "40px", md: "70px"}, width: "auto", marginLeft: 0}} />
      <Stack direction="row" alignItems="center" spacing={2}>
        <SocialIcon href="https://cicap.ru" icon={InternetIcon} />
        <SocialIcon href="https://vk.com/cicap_official" icon={VkIcon} />
        <SocialIcon href="https://t.me/+0D8JeyupoV04OTUy" icon={TelegramIcon} />
        <SocialIcon href="https://www.youtube.com/c/Boristrader/featured" icon={YouTubeIcon} />
        <Typography
          fontSize="small"
          fontWeight="600"
          sx={{
            maxWidth: "14em",
            lineHeight: 1.1,
            display: {xs: "none", md: "block"}
          }}
        >
          Торговля, результаты, лайв-стримы, аналитика
        </Typography>
      </Stack>
    </Stack>
  </Container>
}

export default Header;
