import {Box, Button, Container, Grid, Stack, Typography} from "@mui/material";
import { TelegramIcon } from "../../Icon/TelegramIcon";

const Hero = () => {
  return <Box sx={{ background: "linear-gradient(180deg, #000000, #262626 80%, #202020)" }}>
  <Container maxWidth="xl" sx={{ overflow: "hidden", minHeight: 780, height: {sm: 700, md: 1068 }, pb: "5vh" }}>
      <Grid container pt={{ xs: "120px", md: "220px" }}>
        <Grid item xs={12} sm={6}>
          <Stack mt={{ sm: 0, md: 3.5 }} alignItems="start" width="100%">
            <Typography zIndex={1} fontSize={{ xs: 32, sm: 59, md: 90 }} fontWeight="bold" lineHeight={{ xs: "32px", sm: "59px", md: "95px" }} color="#fff">13 файлов <br /> для  трейдера</Typography>
            <Typography zIndex={1} fontSize={{ xs: 13, sm: 19, md: 30 }} fontWeight="300" mt={{ xs: 2, md: 5 }} color="#fff">
              от ТОПовой команды,<br /> которые сэкономят тебе <br /> месяцы работы и кучу слитых денег!
            </Typography>
            <Box display="flex" justifyContent="center" mt={{ xs: 2, md: 7 }}  >
            <Button color="info" variant="contained" href="https://t.me/Cicap_files_bot" target='_blank'
              onClick={() => {
                //@ts-ignore
                ym(96414864, 'reachGoal', 'button_file')
              }}
              sx={{borderRadius: 6, px: {xs: 4, sm: 5}, height: {xs: 51, md: 65}}}>
              <TelegramIcon sx={{fontSize: {xs: 24, sm: 32}}} />
              <Typography ml={1} sx={{fontSize: {xs: 14, md: 16}}}>
                Забрать файлы бесплатно
              </Typography>
            </Button>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ mt: {xs: 12, sm: 0},
            display: "flex", justifyContent: "center",
            "& img": { transform: "rotate(23deg)",  width: { xs: "180%", sm: "100%"}, height: { xs: 350, sm: "100%" } }
          }} position="relative">
            <img src={"/images/trader-files/business-card-mockup.png"} alt={"team"} />
          </Box>
        </Grid>
      </Grid>

    </Container>
  </Box>
}


export default Hero;